import LinkedInIcon from "@mui/icons-material/LinkedIn";

const About = () => {
  return (
    <div className="bg-blue-500 pt-5 h-auto" id="about">
      <div className="container mx-auto">
        <h1 className="heading pl-[20px] text-[50px] sm:text-[70px] md:text-[100px] font-medium md:mb-[20px] md:py-10" disabled>
          About us
        </h1>
        <div className="services-list grid lg:grid-cols-3 md:grid-cols-1 gap-4 min-w-[250px] justify-center">
          <div className="flex flex-col gap-5 p-[40px] rounded-[20px] items-center">
            <img
              className="object-contain w-[250px] h-[250px] rounded-full"
              src="/kashyap.jpg"
            />
            <div className="w-3/3 justify-center items-center mt-[30px] py-[10px]">
              <h1 className="font-medium text-[27px] font-medium mb-[10px]" disabled>
                {" "}
                Kashyap Anand Kotak
              </h1>
              <p className="text-[17px]" disabled>CEO and Co-founder of Quixilinx</p>
            </div>
          </div>
          <div className="flex flex-col gap-5 p-[40px] rounded-[20px] items-center">
            <img
              className="object-contain w-[250px] h-[250px] rounded-full"
              src="/dimple_kotak.jpg"
            />
            <div className="border-black w-3/3 justify-center items-center mt-[30px] ml-[20px] py-[10px]">
              <h1 className="font-medium text-[27px] font-medium mb-[10px]" disabled>
                {" "}
                Dimple Anand Kotak
              </h1>
              <p className="text-[17px]" disabled>
                Director and Co-founder of Quixilinx
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5 p-[40px] rounded-[20px] items-center">
            <img
              src="/maybe.jpg"
              className="object-contain w-[250px] h-[250px] rounded-full"
            />
            <div className="flex-col w-3/3 justify-center items-center mt-[30px] py-[10px]">
              <h1 className="font-medium text-[27px] font-medium mb-[10px]" disabled>
                {" "}
                Jayraj Nitin Bichave
              </h1>
              <p className="text-[17px]" disabled>CTO and Co-founder of Quixilinx</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
