import Quixilinx_logo from "../assets/Quixilinx_logo/Quixilinx_logo.jpg";

const Footer = () => {
  return (
    <footer className="bg-white shadow dark:bg-gray-900">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <img
            src={Quixilinx_logo}
            className="h-20 mr-3"
            alt="Quixilinx_logo"
          />
          <div className="flex flex-col gap-4 sm:gap-3 justify-start items-start">
            <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase dark:text-white" disabled>
              Contact us
            </h2>
            <ul className="flex flex-col  mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li disabled>+91 7588874767</li>
              <li disabled>contact@quixilinx.com</li>
              <li disabled>
                B-111, Anant Regency, M. M. M. Marg, mulund West, Mumbai -
                400080{" "}
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      </div>
    </footer>
  );
};

export default Footer;
