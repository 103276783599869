import { Code, Business, PhoneAndroid, School, Draw } from "@mui/icons-material";
import { motion, useAnimation, useInView } from "framer-motion";
import { useRef, useEffect } from "react";
import training from "../assets/education.png";
import "../App.css";

const Services = () => {
  const ref = useRef(null);
  const isinview = useInView(ref, { once: false });
  const animation = useAnimation();

  useEffect(() => {
    if (isinview) {
      animation.start({
        opacity: 1,
      });
      if (!isinview) {
        animation.end({
          scale: 0,
        });
      }
    }
  }, [isinview]);

  return (
    <div className=" bg-blue-500 md:h-[100vh] w-auto " id="services">
      <div className="md:container mx-auto">
        <h1 className=" pl-[2px] text-[45px] sm:text-[70px] md:text-[100px] font-medium ">
          Our Services
        </h1>
        <motion.div
          initial={{ opacity: 0 }}
          animate={animation}
          transition={{ duration: 1, delay: 0.25 }}
          ref={ref}
          className="flex md:flex-row flex-col pt-[3vh] gap-7 max-w-[100vw]"
        >
          <motion.div className="max-w-[100vw] px-[20px] py-[10px] border-white rounded-[20px] md:transition ease-in-out md:hover:-translate-y-2 duration-100">
            <Code sx={{ fontSize: 50 }} className=" mb-[10px]" />
            <h1 className="text-[30px] font-medium mb-[10px]" disabled>
              Web Development
            </h1>
            <p className="text-[17px]" disabled>
              Get your own customized web pages and take your business to the
              "Internet".
            </p>
          </motion.div>
          <motion.div className="max-w-[100vw] px-[20px] py-[10px] border-white rounded-[20px]  md:transition ease-in-out md:hover:-translate-y-2 duration-100">
            <PhoneAndroid sx={{ fontSize: 50 }} className=" mb-[10px]" />
            <h1 className="text-[30px] font-medium mb-[10px]" disabled>
              Mobile development
            </h1>
            <p className="text-[17px]" disabled>
              Want your services to be at everyones fingertips, don't worry
              we've perfectly got you covered.
            </p>
          </motion.div>
          <motion.div className="max-w-[100%] px-[20px] py-[10px] rounded-[20px] md:transition ease-in-out md:hover:-translate-y-2 duration-100">
            <Business sx={{ fontSize: 50 }} className=" mb-[10px]" />
            <h1 className="text-[30px] font-medium mb-[10px]" disabled>
              ERP development
            </h1>
            <p className="text-[17px]" disabled>
              Convert your offline SOPs to a complete online solutions and grow
              faster.
            </p>
          </motion.div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={animation}
          transition={{ duration: 1, delay: 0.25 }}
          ref={ref}
          className="flex mt-20 md:flex-row flex-col max-w-[95vw] md:max-w-[70vw] w-full  mx-auto pt-[3vh] gap-7 "
        >
          <motion.div className="max-w-[100vw] px-[20px] py-[10px] border-white rounded-[20px] md:transition ease-in-out md:hover:-translate-y-2 duration-100">
            <School sx={{ fontSize: 50 }} className=" mb-[10px]" />
            <h1 className="text-[30px] font-medium mb-[10px]" disabled>
              Industry Training
            </h1>
            <p className="text-[17px]" disabled>
              We train employees as well as students to match industrial technology standards
            </p>
          </motion.div>
          <motion.div className="max-w-[100vw] px-[20px] py-[10px] border-white rounded-[20px] md:transition ease-in-out md:hover:-translate-y-2 duration-100">
            <Draw sx={{ fontSize: 50 }} className=" mb-[10px]" />
            {/* <img src={ux} className="h-10 w-10 mb-[10px]" /> */}
            <h1 className="text-[30px] font-medium mb-[10px]" disabled>
              UI Design
            </h1>
            <p className="text-[17px]" disabled>
              Upgrade your application in terms of looks and feels so that your clients enjoy your products.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
